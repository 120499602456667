<template>
	<transition name="fade">
		<section v-if="!loading" class="application-tab">
			<ApplicationStatus
				v-if="!isDeclined"
				class="loan-section"
				:moduleName="moduleName"
				:reload="reload"
				@submit="reload = true"
				@reloaded="reload = false"
			></ApplicationStatus>

			<ApplicationDetail
				v-if="!isDeclined"
				class="loan-section"
				:moduleName="moduleName"
				:model="application"
				:disclosures="disclosures"
				:reload="reload"
				@submit="refresh"
				@reloaded="reload = false"
			/>

			<ApplicationTasks v-if="!isDeclined" :moduleName="moduleName" :model="application" @submit="refresh" />


			<ApplicationDisclosures v-if="!isDeclined" :moduleName="moduleName" :disclosures="disclosures" />

			<DeclinedMessage v-if="isDeclined" class="loan-section" />
		</section>
	</transition>
</template>

<script>
	import { DeclinedMessage } from '@/components';
	import { LoanDecision } from '@/constants';
	import showSpinner from '@/mixins/showSpinner';
	import { toastConfig } from '@/utils';
    import { createToastInterface } from 'vue-toastification';
    import { Portal } from 'portal-vue';

	const toast = createToastInterface(toastConfig);

	export default {
		name: 'ApplicationTab',
		components: {
			DeclinedMessage,
			ApplicationDetail: () => import('@/views/Partials/ApplicationDetail'),
			ApplicationStatus: () => import('@/views/Partials/ApplicationStatus'),
			ApplicationTasks: () => import('@/views/Partials/ApplicationTasks'),
			ApplicationDisclosures: () => import('@/views/Partials/ApplicationDisclosures'),
			Portal
		},
		mixins: [showSpinner],
		props: {
			moduleName: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				applicationTitle: '',
				application: {
					productTypeId: 0,
					loanDecision: 0,
					loanDetail: {
						totalLoanAmount: '',
						loanRate: '',
						loanTermInMonths: '',
						monthlyPayment: ''
					}
				},
				disclosures: [],
				reload: false
			};
		},
		computed: {
			isDeclined() {
				return this.application.loanDecision === this.loanDecision.DECLINED;
			},
			loanDecision() {
				return {
					DECLINED: LoanDecision.DECLINED
				};
			}
		},
		async created() {
			await this.load();
		},
		methods: {
			async load() {
				try {
					this.showSpinner({ loading: true });

					this.application = await this.$store.dispatch(`${this.moduleName}/getApplication`);
                    this.disclosures = await this.$store.dispatch(`${this.moduleName}/getApplicationDisclosures`);
					await this.$store.dispatch(`${this.moduleName}/getWelcomeMessage`);

					return this.showSpinner({ loading: false });
				} catch (error) {
					this.showSpinner({ submitting: false });
					return toast.error(error);
				}
			},
			async refresh() {
				await this.load();
				this.reload = false;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.application-tab {
		.loan-section {
			margin-bottom: 1em;
		}
	}
</style>
